import axios from 'axios';
import authHeader from "@/services/auth-header";
import ConstApiUrls from "@/services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FtRegistrasiLogService {
    getApiUrl() {
        return API_URL
    }
    getAllFtRegistrasiLog(){
        return axios.get(API_URL + `getAllFtRegistrasiLog`, { headers: authHeader() });
    }
    getAllFtRegistrasiLogContaining(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFtRegistrasiLog`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFtRegistrasiLogContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getFtRegistrasiLogById(id){
        return axios.get(API_URL + `getFtRegistrasiLogById/${id}`, { headers: authHeader() });
    }
    getFtRegistrasiLogByIdPublic(id){
        return axios.get(API_URL + `public/getFtRegistrasiLogById/${id}`, { headers: authHeader() });
    }
    getAllFtRegistrasiLogByParent(id){
        return axios.get(API_URL + `getAllFtRegistrasiLogByParent/${id}`, { headers: authHeader() });
    }
    getAllFtRegistrasiLogByParentPublic(id){
        return axios.get(API_URL + `public/getAllFtRegistrasiLogByParent/${id}`, { headers: authHeader() });
    }


    updateFtRegistrasiLog(item){
        return axios.put(API_URL + `updateFtRegistrasiLog/${item.id}`, item, {headers: authHeader()})
    }
    createFtRegistrasiLog(item){
        return axios.post(API_URL + `createFtRegistrasiLog`, item, {headers: authHeader()})
    }
    deleteFtRegistrasiLog(id){
        return axios.delete(API_URL + `deleteFtRegistrasiLog/${id}`, {
            headers: authHeader()
        });
    }

    deleteFtRegistrasiLogAvatarByParent(fkegiatanBean){
        return axios.delete(API_URL + `deleteFtRegistrasiLogAvatarByParent/${fkegiatanBean}`, {
            headers: authHeader()
        });
    }
    createFtRegistrasiLogAvatar(item){
        return axios.post(API_URL + `createFtRegistrasiLogAvatar`, item, {headers: authHeader()})
    }

    deleteAllFtRegistrasiLog(itemIds){

        return axios.delete(API_URL + `deleteAllFtRegistrasiLog`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FtRegistrasiLogService()