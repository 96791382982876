const ERegistrasiLog = Object.freeze({
  SUBMIT: 1,
  TAKE: 2,
  RELEASE: 3,
  VALID: 4,
  REJECT: 5,
  DOWNLOAD: 6,
  APPROVE: 7,
});
const ERegistrasiLogs = Object.freeze([
  {
    id: ERegistrasiLog.SUBMIT,
    description: "Submit berkas",
  },
  {
    id: ERegistrasiLog.TAKE,
    description: "Pengambilan berkas Usulan untuk ditangani oleh Verifikator",
  },
  {
    id: ERegistrasiLog.RELEASE,
    description: "Verifikator tidak jadi menangani berkas usulan",
  },
  {
    id: ERegistrasiLog.VALID,
    description: "Verifikator Memberikan Status Berkas Sudah Valid",
  },
  {
    id: ERegistrasiLog.REJECT,
    description: "Verifikator Memberikan Status Berkas Tidak Valid",
  },
  {
    id: ERegistrasiLog.DOWNLOAD,
    description: "Melakukan download berkas usulan",
  },
  {
    id: ERegistrasiLog.APPROVE,
    description: "Melakukan approval berkas",
  },
]);
export { ERegistrasiLog as default, ERegistrasiLogs };
