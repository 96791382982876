import axios from 'axios';
import authHeader from "@/services/auth-header";
import ConstApiUrls from "@/services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class ApiSimpegService {
    getApiUrl() {
        return API_URL
    }

    getPostSimpegDataPegawai(nip){
        return axios.post(API_URL + `public/getPostSimpegDataPegawai`, null, {
            params: {nip: nip},
            headers: authHeader()
        });
    }
    getSimpegToken(){
        return axios.get(API_URL + `public/getSimpegToken`, { headers: authHeader() });
    }
}
export default new ApiSimpegService()