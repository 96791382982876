<template>
  <div>
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1"
          >Pilih Usulan</v-stepper-step
        >
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 2" step="2">Data ASN</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 3" step="3"
          >Upload Berkas</v-stepper-step
        >
        <v-divider></v-divider>
        <v-stepper-step step="4">Preview</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <FtRegistrasiLayananDesc1></FtRegistrasiLayananDesc1>
          <div class="d-flex">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="selanjutnyaStep2">Selanjutnya</v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <FtRegistrasiLayananDesc2 class="mb-5"></FtRegistrasiLayananDesc2>
          <div class="d-flex">
            <v-btn color="secondary" @click="step = 1">Sebelumnya</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="selanjutnyaStep3">Selanjutnya</v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="3">
          <FtRegistrasiLayananDoc class="mb-4"></FtRegistrasiLayananDoc>

          <div class="d-flex">
            <v-btn color="secondary" @click="step = 2">Sebelumnya</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="selanjutnyaStep4">Selanjutnya</v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="4">
          <FtRegistrasiLayananSummary
            @submit="submit"
            @setStep3="setStep"
          ></FtRegistrasiLayananSummary>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

  </div>
</template>

<script>
import FtRegistrasiLayananDesc1 from "@/components/admin_registrasi_layanan_edit/FtRegistrasiLayananDesc1.vue";
import FtRegistrasiService from "@/services/apiservices/ft-registrasi-service";
import FDivisionService from "@/services/apiservices/f-division-service";
import FJenisDokumenService from "@/services/apiservices/f-jenis-dokumen-service";
import FJenisJabatanService from "@/services/apiservices/f-jenis-jabatan-service";
import FJenisProsedurService from "@/services/apiservices/f-jenis-prosedur-service";
import FJenisKenaikanPangkatService from "@/services/apiservices/f-jenis-kenaikan-pangkat-service";
import FJenisKartuService from "@/services/apiservices/f-jenis-kartu-service";
import FTemplateRegService from "@/services/apiservices/f-template-reg-service";
import FtRegistrasiLayananDesc2 from "@/components/admin_registrasi_layanan_edit/FtRegistrasiLayananDesc2.vue";
import FtRegistrasiFileService from "@/services/apiservices/ft-registrasi-file-service";
import FTemplateRegDokumenService from "@/services/apiservices/f-template-reg-dokumen-service";
import FtRegistrasiLayananDoc from "@/components/admin_registrasi_layanan_edit/FtRegistrasiLayananDoc.vue";
import FtRegistrasiLayananSummary from "@/components/admin_registrasi_layanan_edit/FtRegistrasiLayananSummary.vue";
import ApiFonnteService from "@/services/apiservices-ext/api-fonnte-service";
import FtRegistrasiLog from "@/models/ft-registrasi-log";
import FtRegistrasiLogService from "@/services/apiservices/ft-registrasi-log-service";
import ERegistrasiLog, {ERegistrasiLogs} from "@/models/e-registrasi-log";

export default {
  name: "FtRegistrasiLayananEditStepper",
  components: {
    FtRegistrasiLayananSummary,
    FtRegistrasiLayananDoc,
    FtRegistrasiLayananDesc2,
    FtRegistrasiLayananDesc1,
  },
  data() {
    return {
      step: 1,
      itemsERegistrasiLog: ERegistrasiLogs,
      ERegistrasiLog: ERegistrasiLog
    };
  },
  computed: {

    currentUser() {
      return this.$store.state.auth.user;
    },

    itemDefault: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemDefault;
      },
      set(val) {
        this.$store.dispatch("FtRegistrasiModule/updateItemDefault", val);
      },
    },
    itemModified: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemModified;
      },
      set(val) {
        this.$store.dispatch("FtRegistrasiModule/updateItemModified", val);
      },
    },

    itemsFDivision: {
      get() {
        return this.$store.state.FTemplateRegModule.itemsFDivision;
      },
      set(value) {
        this.$store.dispatch("FTemplateRegModule/updateItemsFDivision", value);
      },
    },
    itemsFJenisDokumen: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFJenisDokumen;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFJenisDokumen",
          value
        );
      },
    },
    itemsFJenisJabatan: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFJenisJabatan;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFJenisJabatan",
          value
        );
      },
    },
    itemsFJenisProsedur: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFJenisProsedur;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFJenisProsedur",
          value
        );
      },
    },
    itemsFJenisKenaikanPangkat: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFJenisKenaikanPangkat;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFJenisKenaikanPangkat",
          value
        );
      },
    },
    itemsFJenisKartu: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFJenisKartu;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFJenisKartu",
          value
        );
      },
    },

    itemsFTemplateReg: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFTemplateReg;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFTemplateReg",
          value
        );
      },
    },

    itemsFTemplateRegDokumen: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFTemplateRegDokumen;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFTemplateRegDokumen",
          value
        );
      },
    },
    itemFTemplateRegBean: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemFTemplateRegBean;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemFTemplateRegBean",
          value
        );
      },
    },

    itemsFtRegistrasiFile: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFtRegistrasiFile;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFtRegistrasiFile",
          value
        )
      }
    },

  },
  watch: {
  },
  methods: {
    submit() {
      this.itemModified.submitted = true
      let waListAdmin = this.lookupItemTemplateReg(this.itemModified.ftemplateRegBean).whatsAppAdmin.split(",")
      let pesan = `Usulan ${this.lookupItemTemplateReg(this.itemModified.ftemplateRegBean).description}, ${this.itemModified.description} - ${this.itemModified.nip} telah di submit. Mohon segera ditindak lanjuti`
      waListAdmin.forEach((item) => {
        const simpleMessage = {
          to: item,
          subject: "Admin Silayak",
          message: pesan,
        };
        ApiFonnteService.postFonnteSendMessagePublic(
            simpleMessage
        ).then(() => {});
      })
      if (this.currentUser.phone !== undefined) {
        if (this.currentUser.phone !== ""){
          this.itemModified.toNumber = this.currentUser.phone
        }
      }
      FtRegistrasiService.updateFtRegistrasi(this.itemModified).then(
        response => {
          let newItemFtRegistrasiLog = new FtRegistrasiLog()
          newItemFtRegistrasiLog.ftRegistrasiBean = response.data.id
          newItemFtRegistrasiLog.actionType = 'UPDATE'
          newItemFtRegistrasiLog.oldData = JSON.stringify(this.itemDefault)?JSON.stringify(this.itemDefault):"{}"
          newItemFtRegistrasiLog.newData = JSON.stringify(this.itemModified)?JSON.stringify(this.itemModified):"{}"
          newItemFtRegistrasiLog.remarks = this.lookupItemsERegistrasiLog(ERegistrasiLog.SUBMIT).description
          newItemFtRegistrasiLog.modifiedBy = this.currentUser.username

          FtRegistrasiLogService.createFtRegistrasiLog(newItemFtRegistrasiLog).then(
              () => {
              },
              error => {
                console.log(error.response)
              }
          )

          this.itemModified = response.data
          this.$router.push("/registrasi-layanan")
        },
        error => {
          console.log(error.response)
        }
      )

    },
    setStep(value) {
      this.step = value;
    },
    selanjutnyaStep2() {
      this.step = 2;
      this.saveFirst();
    },
    selanjutnyaStep3() {
      this.step = 3;
      this.saveFirst();
      /**
       * Jika FtRegistrasiFile tipe Doc masih kosong maka buatkan
       */
      if (this.itemModified.id !==undefined){
        FtRegistrasiFileService.getAllFtRegistrasiFileByFtRegistrasiBeanAndFileType(
            this.itemModified.id,
            "DOC"
        ).then((response) => {
          if (response.data.length === 0) {
            this.createFtRegistrasiFileFirst();
          } else {
            this.itemsFtRegistrasiFile = response.data;
            /*
             * Algoritma yang Aneh yang akan diperbaiki
             * Kita masih tetap bisa menggunakan "itemsFtRegistrasiFile"
             */
            // this.itemsFtRegistrasiFileFiltered = response.data.filter(
            //     (item) => item.fileName === "" && item.mandatory === true
            // )

          }
        });

      }
    },
    selanjutnyaStep4() {
      this.step = 4;
      this.saveFirst();
    },
    saveFirst() {
      this.itemModified.modifiedBy = this.currentUser.username;
      // console.log(this.itemModified)
      FtRegistrasiService.updateFtRegistrasi(this.itemModified).then(
        (response) => {
          this.itemModified = response.data;
          // console.log(JSON.stringify(response.data))
        },
        (error) => {
          console.log(error.response);
        }
      );
    },
    createFtRegistrasiFileFirst() {
      const payloadMulti = {
        ftRegistrasiBean: this.itemModified.id,
        fileType: "DOC",
        ftemplateRegDokumenIds: this.itemsFTemplateRegDokumen.map(
          (item) => item.id
        ),
      };

      FtRegistrasiFileService.createFtRegistrasiFileMulti(payloadMulti).then(
        () => {
          this.fetchFtRegistrasiFile(this.itemModified.id);

        }
      );
    },
    fetchFtRegistrasiFile(ftRegistrasiBean) {
      if (ftRegistrasiBean !== undefined) {
        FtRegistrasiFileService.getAllFtRegistrasiFileByFtRegistrasiBeanAndFileType(
          ftRegistrasiBean,
          "DOC"
        ).then((response) => {
          this.itemsFtRegistrasiFile = response.data;
          /**
           * Diganti dengan Algoritma yang Lebih Bagu
           */
          // this.itemsFtRegistrasiFileFiltered = response.data.filter(
          //   (item) => item.fileName === "" && item.mandatory === true
          // )

        })
      }

    },
    async fetchParent() {
      if (this.currentUser.organizationLevel === "DIV") {
        FDivisionService.getFDivisionById(this.currentUser.fdivisionBean).then(
          (response) => {
            this.itemsFDivision = [response.data];
            // console.log(response.data.items)
          },
          (error) => {
            console.log(error.response);
          }
        );
      } else {
        FDivisionService.getAllFDivision().then(
          (response) => {
            this.itemsFDivision = response.data;
          },
          (error) => {
            console.log(error.response);
          }
        );
      }
      FJenisDokumenService.getAllFJenisDokumen().then(
        response => {
          this.itemsFJenisDokumen = response.data.filter(
            (item) => item.statusActive === true
          );
        },
        (error) => {
          console.log(error.response);
        }
      );
      FJenisJabatanService.getAllFJenisJabatan().then(
        response => {
          this.itemsFJenisJabatan = response.data.filter(
            (item) => item.statusActive === true
          );
        },
        (error) => {
          console.log(error.response);
        }
      );
      FJenisProsedurService.getAllFJenisProsedur().then(
        response => {
          // console.log(JSON.stringify(response.data))
          this.itemsFJenisProsedur = response.data.filter(
            (item) => item.statusActive === true
          );
        },
        (error) => {
          console.log(error.response);
        }
      );
      FJenisKenaikanPangkatService.getAllFJenisKenaikanPangkat().then(
        (response) => {
          this.itemsFJenisKenaikanPangkat = response.data.filter(
            (item) => item.statusActive === true
          );
        },
        (error) => {
          console.log(error.response);
        }
      );
      FJenisKartuService.getAllFJenisKartu().then(
        (response) => {
          this.itemsFJenisKartu = response.data.filter(
            (item) => item.statusActive === true
          );
        },
        (error) => {
          console.log(error.response);
        }
      );
      FTemplateRegService.getAllFTemplateReg().then(
        (response) => {
          // console.log(JSON.stringify(response.data))
          this.itemsFTemplateReg = response.data;
        },
        (error) => {
          console.log(error.response);
        }
      );
    },
    lookupItemTemplateReg(fTemplateRegBean) {
      const str = this.itemsFTemplateReg.filter(
          (x) => x.id === fTemplateRegBean
      );
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
    lookupItemsERegistrasiLog(id) {
      const str = this.itemsERegistrasiLog.filter(
          (x) => x.id === id
      );
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
  },
  mounted() {
    /**
     * 1. Fetch Parent: itemsFDivision, itemsFJenisDokumen, itemsFJenisJabatan, itemsFJenisProsedur, itemsFJenisKenaikanPangkat, itemsFJenisKartu, itemsFTemplateReg
     * 2. isi itemModified by ID
     * 3. isi itemsFTemplateRegDokumen by Parent (itemModified.ftemplateRegBean) terlebih dahulu agar tidak ada telat
     * 4. isi itemsFtRegistrasiFile by itemModified (parent)
      */

    //Apakah bisa melakukan ini dulu sampai semua selesai baru selanjutnya?
    this.fetchParent(); //Menggunaak await

    const id = this.$route.params.id;
    FtRegistrasiService.getFtRegistrasiById(id).then((response) => {

      this.itemModified = response.data;
      this.itemDefault= JSON.parse(JSON.stringify(response.data));

      FTemplateRegDokumenService.getAllFTemplateRegDokumenByParent(this.itemModified.ftemplateRegBean).then(
        response => {
          this.itemsFTemplateRegDokumen = response.data;

          this.fetchFtRegistrasiFile(this.itemModified.id);

        },
        (error) => {
          console.log(error.response);
        }
      );
    });
  },
};
</script>

<style scoped>
</style>