export default class FtRegistrasiLog {
  constructor(
    id,
    ftRegistrasiBean,
    actionType,
    oldData,
    newData,
    remarks,

    created,
    modifiedBy
  ) {
    this.id = id;
    this.ftRegistrasiBean = ftRegistrasiBean;
    this.actionType = actionType;
    this.oldData = oldData;
    this.newData = newData;
    this.remarks = remarks;

    this.created = created;
    this.modifiedBy = modifiedBy;
  }

}
